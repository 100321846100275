
import { Injectable } from "@angular/core";


@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {

  //STAGING MVP
  baseUrl2 = "https://hubadminapi.jgi.bigcityvoucher.co.in/v1";


  imageUrl = 'https://hubadminapi.jgi.bigcityvoucher.co.in/v1';

  // onboard
  login = this.baseUrl2 + "/dashboard/login";
  otp = this.baseUrl2 + "/dashboard/verifyotp";
  resendOtp = this.baseUrl2 + "/dashboard/resendotp";
  //
  channels = this.baseUrl2 + "/dashboard/channels";
  regions = this.baseUrl2 + "/dashboard/regions";
  userList = this.baseUrl2 + "/dashboard/supervisors-list";
  townList = this.baseUrl2 + "/dashboard/towns-list";
  outletList = this.baseUrl2 + "/dashboard/outlets-list";
  regionalHead = this.baseUrl2 + "/dashboard/regional-heads-list";
  nepaliMonth = this.baseUrl2 + "/users/nepali-months"
  enrollmentDashboard = this.baseUrl2 + "/dashboard/dashboard-enrollment";
  tagetvsAchievement = this.baseUrl2 + "/dashboard/dashboard-target-achievement";
  dashboardEngagements = this.baseUrl2 + "/dashboard/dashboard-engagements"
  dashboardRedumption = this.baseUrl2 + "/dashboard/dashboard-redemptions";
  // reports 
  listOfReport = this.baseUrl2 + "/dashboard/dashboard-reports";
  requestReport = this.baseUrl2 + "/dashboard/dashboard-report-request";
  listofReportLinkData = this.baseUrl2 + "/dashboard/dashboard-report-request-list";










  
  logout = this.baseUrl2 + "/login/logout"
  programConfig = this.baseUrl2 + "/programs/get-programs-configuration?program_id=1";
  get_all_the_programs = this.baseUrl2 + '/programs/get-programs?id=';
  update_login_program = this.baseUrl2 + "/login/update-program"
  setupProgram = this.baseUrl2 + '/application-config/index?pwd='
  clearDataBase = this.baseUrl2 + '/application-config/delete-all'


  runScheduler = "http://api.billsnap.bigcityvoucher.co.in:6004/api/v1/apicall";
}
export interface ApiConfigurationInterface { }